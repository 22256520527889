@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.form {
  width: 400px;
  height: auto;
  padding: 25px;

  background-color: color(app-white);
  border: 1px solid color(marriott-light-grey-2);
  border-radius: $main-border-radius;
  box-shadow: $main-box-shadow;

  .input-wrapper {
    margin-bottom: 10px;
  }

  .title {
    margin-bottom: 20px;

    font-weight: bold;
    font-size: 18px;
  }
}
