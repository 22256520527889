.basic-select {
  //

  label {
    display: flex;
    padding-bottom: 6px;

    .title {
      font-weight: 700;
      font-size: 12px;
    }

    .loading-wrapper {
      margin-left: 8px;
    }
  }
}
