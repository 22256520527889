@import "@STYLES/colors.scss";

.edit-chapter-sidebar {
  position: relative;

  width: 100%;

  .wrapper {
    padding: 15px 25px 25px 0;
  }
}
