@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.sections {
  //

  .sections-title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    .sections-title {
      margin-right: 15px;

      font-weight: 700;
      font-size: 22px;
    }

    .grid-view {
      margin-right: 15px;

      .icon-button {
        padding: 10px;
      }
    }

    .list-view {
      .icon-button {
        padding: 10px 8px;
      }
    }

    .grid-view,
    .list-view {
      //

      .icon-button {
        background-color: transparent;
        border: 2px solid transparent;
        border-radius: 50%;

        svg {
          // fill: color(marriott-dark-grey);
        }
      }

      &.active {
        .icon-button {
          border: 2px solid color(marriott-light-grey);

          svg {
            // fill: color(app-white);
          }
        }
      }
    }
  }

  .sections-lists-wrapper {
    //

    .sections-list {
      margin-bottom: 50px;

      .chapter-title-wrapper {
        //
      }
    }
  }

  .sections-not-found,
  .chapter-has-body {
    margin-top: 40px;

    color: color(marriott-light-grey);
    font-size: 16px;
  }
}
