// stylelint-disable selector-class-pattern

@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";
@import "@STYLES/mixins.scss";

.basic-react-select-container {
  //

  .RS__menu {
    padding: 5px;

    background-color: white;
    border: 1px solid color(marriott-light-grey);
    border-radius: $main-border-radius;
    box-shadow: none;
  }

  .RS__single-value {
    //
  }

  .RS__menu-list {
    @include custom-scrollbar;

    padding-top: 5px;
    padding-right: 5px;
    padding-left: 5px;
  }

  .RS__control {
    height: 36px;
    min-height: 36px;

    font-size: 12px;

    border: 1px solid color(marriott-light-grey);
    border-radius: $main-border-radius;
    box-shadow: none;
    cursor: pointer;

    .RS__clear-indicator {
      padding: 7px;

      svg {
        fill: color(marriott-light-grey);
      }
    }

    .RS__indicator-separator {
      background-color: color(marriott-light-grey-2);
    }

    .RS__dropdown-indicator {
      height: 32px;
      padding: 6px;

      transform: none;

      svg {
        fill: color(marriott-light-grey);
      }
    }

    &--is-disabled {
      //
    }

    &--is-focused,
    &--is-selected {
      border: 1px solid color(marriott-light-grey) !important;
    }

    &--menu-is-open .RS__dropdown-indicator {
      transform: rotate(180deg);
    }
  }

  .RS__option {
    z-index: 5;

    display: flex;
    align-items: center;
    padding: 12px;

    font-size: 12px;

    border-radius: $main-border-radius;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &:hover,
    &--is-focused {
      // color: $main-color;
    }
  }
}
