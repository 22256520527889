@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.modal-body {
  $info-width: 310px;
  position: relative;

  display: flex;
  flex: 1;
  width: 100%;
  min-height: 100%;

  .pseudo-sidebar {
    position: absolute;
    top: 0;
    bottom: 0;

    width: $info-width;

    background-color: color(app-white);
    border-right: 1px solid color(marriott-light-grey-2);
    box-shadow: $main-box-shadow;
  }

  .history {
    width: 100%;
    height: 100%;

    .item-wrapper {
      position: relative;
      z-index: 1;

      display: flex;
      width: 100%;

      &::before {
        $height: 2px;
        position: absolute;
        top: -$height;
        left: 0;
        z-index: 2;

        width: 100%;
        height: $height;

        background-color: color(marriott-primary);

        content: "";
      }

      &:not(:last-child) {
        border-bottom: 1px solid color(marriott-light-grey-2);
      }

      .item-info-wrapper {
        position: relative;

        width: $info-width;
        min-width: $info-width;
        padding-right: 25px;
      }

      .item-history-wrapper {
        width: calc(100% - $info-width);
      }
    }
  }
}
