@import "@STYLES/colors.scss";

.page {
  padding-top: 50px;

  .not-found {
    width: 100%;

    color: color(marriott-light-grey);
    font-weight: 700;
    font-size: 18px;
    text-align: center;
  }
}
