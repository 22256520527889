@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.admin-breadcrumbs {
  position: relative;

  width: 100%;
  height: $admin-breadcrumbs-height;

  background-color: color(app-white);
  border-bottom: 1px solid color(marriott-light-grey-2);
  box-shadow: $main-box-shadow;

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .crumbs-wrapper {
      display: flex;
      align-items: center;
      height: 100%;
    }

    .searchbar-wrapper {
      width: 400px;
      height: 100%;
      margin-left: 50px;
      padding-bottom: 1px;
    }
  }
}
