@import "@STYLES/colors.scss";

.chapter-name {
  display: flex;
  align-items: center;
  padding-bottom: 50px;

  background-color: color(app-white);

  .name {
    margin-right: 15px;

    font-weight: 700;
    font-size: 24px;
  }

  .preview-chapter {
    margin-left: 10px;
  }

  .edit-chapter-name,
  .preview-chapter {
    display: flex;
    align-items: center;

    border: 1px solid color(marriott-light-grey);
    border-radius: 50%;

    &:hover {
      border-color: color(marriott-primary);

      button svg {
        fill: color(marriott-primary);
      }
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;
    }
  }
}
