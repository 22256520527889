@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.user-body {
  overflow: hidden;

  border-radius: $main-border-radius - 1px;

  .body-wrapper {
    $padding: 30px;
    display: flex;
    justify-content: space-between;

    background-color: color(app-white);

    .user-info-wrapper {
      min-width: 350px;
      padding: $padding;

      background-color: color(app-white);
      border-right: 1px solid color(marriott-light-grey-2);
      box-shadow: $main-box-shadow;
    }

    .user-options {
      width: 100%;
      padding: $padding;

      .permissions-wrapper {
        margin-bottom: 30px;
      }

      .properties-wrapper {
        //
      }
      .roles-wrapper{
        margin-bottom: 30px;
      }

      .button-wrapper {
        width: 33%;
        min-width: 100px;
        margin-top: 30px;
      }
    }
  }
}
