.section-vendors-controls {
  display: flex;
  align-items: center;

  .select-wrapper {
    position: relative;

    width: 260px;
    margin-right: 10px;
  }

  .button-wrapper {
    display: flex;
    align-self: flex-end;
  }
}
