@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.add-button {
  position: relative;

  width: 100%;
  height: 100%;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60px;

    color: color(marriott-light-grey);

    background-color: color(app-white);
    border: 2px solid color(marriott-light-grey);
    border-radius: $main-border-radius;

    .title,
    .plus {
      display: flex;
      align-items: center;
      height: 100%;

      color: inherit;
    }

    .title {
      margin-right: 10px;

      font-weight: 700;
      font-size: 14px;
    }

    .plus {
      color: inherit;
    }
  }

  .lock-wrapper {
    position: absolute;
    top: -20px;
    right: -20px;
    z-index: 1;
  }

  &.locked {
    pointer-events: none;
  }

  &.small {
    button {
      min-height: 40px;

      border-width: 1px;

      .title {
        margin-right: 7px;

        font-weight: 500;
        font-size: 12px;
      }

      .plus {
        font-size: 22px;
      }
    }

    .lock-wrapper {
      top: -14px;
      right: -14px;
    }
  }

  &:hover {
    button {
      color: color(app-green);

      border-color: color(app-green);
    }
  }
}
