@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.chapter {
  $main-color: color(marriott-primary);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 2px;

  background-color: $main-color;
  border-radius: $main-border-radius;

  .chapter-info-wrapper {
    position: relative;

    padding: 20px;

    .accent {
      position: absolute;
      top: 3px;
      left: 0;

      width: 6px;
      height: calc(100% - 3px);

      background-color: color(app-yellow);
      border-top-right-radius: $main-border-radius;
      border-bottom-right-radius: $main-border-radius;
    }

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;

      .chapter-number {
        display: flex;

        color: color(app-white);
        font-weight: 700;
        font-size: 14px;
      }
    }

    .title {
      color: color(app-white);
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      text-align: right;
    }
  }

  .sections {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 2px 2px 0;
    padding: 15px;

    background-color: color(app-white);
    border-radius: $main-border-radius;

    .introduction-text-warning {
      margin-bottom: 15px;
    }

    .sections-title {
      margin-bottom: 20px;

      font-weight: 700;
      font-size: 14px;
    }

    .sections-list {
      //

      .section-item {
        display: flex;
        width: fit-content;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        .warning-icon {
          margin-left: 8px;
        }
      }
    }

    .no-sections {
      color: color(marriott-light-grey);
      font-size: 14px;
    }
  }
}
