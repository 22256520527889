@import "@STYLES/colors.scss";

.page-header {
  padding: 50px 0;

  background-color: color(app-white);

  .chapter-options {
    //

    .options {
      display: flex;

      .option {
        display: flex;
        align-items: center;
        height: 50px;
        padding-right: 30px;

        &:not(:last-child) {
          margin-right: 30px;

          border-right: 1px solid color(marriott-light-grey-2);
        }

        .checkbox-title {
          margin-right: 15px;

          color: color(marriott-light-grey);
          font-weight: 700;
          font-size: 14px;
        }
      }
    }

    .save-button-wrapper {
      margin-top: 30px;
    }
  }
}
