@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";
@import "@STYLES/mixins.scss";

.variant-body-sidebar {
  position: relative;
  z-index: 2;

  width: 300px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  background-color: color(app-white);
  border-left: 1px solid color(marriott-light-grey-2);
  box-shadow: $main-box-shadow;

  @media (max-width: 1500px) {
    width: 100%;

    border-left: none;

    .sidebar-wrapper {
      position: static !important;

      .button-wrapper {
        width: 50% !important;
      }
    }
  }

  @include custom-scrollbar($thumb-color: transparent, $track-color: rgba(249, 249, 249, 1));

  &:hover {
    @include custom-scrollbar($thumb-color: lightgrey, $track-color: rgba(249, 249, 249, 1));
  }

  .sidebar-wrapper {
    position: absolute;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .version-control {
      width: 100%;
      padding: 25px;

      .title {
        margin-bottom: 30px;

        color: color(marriott-primary);
        font-weight: 700;
        font-size: 14px;
      }

      .versions {
        margin-bottom: 30px;
      }
    }

    .footer {
      position: sticky;
      bottom: -1px;

      padding: 15px 25px;

      background-color: color(app-white);
      border-top: 1px solid color(marriott-light-grey-2);

      .footer-top {
        margin-bottom: 15px;
      }
    }
  }
}
