@import "@STYLES/colors.scss";

.sections-sticky-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;

  background-color: color(app-white);

  .sections-title-wrapper {
    display: flex;
    align-items: baseline;

    white-space: nowrap;

    .title {
      margin-right: 10px;

      color: color(marriott-primary);
      font-weight: 700;
      font-size: 18px;
    }
  }

  .buttons-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 20px;

    border-left: 1px solid color(marriott-light-grey-2);

    .order-sections-button {
      display: flex;
      justify-self: flex-end;

      transition: margin-right 0.1s ease-in-out;
    }
  }
}
