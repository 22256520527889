@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.searchbar-input {
  width: 100%;
  height: 100%;

  .input-wrapper {
    position: relative;
    z-index: 12;

    display: flex;
    width: 100%;
    height: 100%;

    background-color: color(app-white);
    border-left: 1px solid color(marriott-light-grey-2);

    .icon {
      position: absolute;
      top: 50%;
      left: 18px;

      display: flex;

      transform: translateY(-50%);

      svg {
        fill: color(marriott-light-grey);
      }
    }

    input[type="text"] {
      width: 100%;
      height: 100%;
      padding-right: 15px;
      padding-left: 55px;

      font-size: 12px;

      background-color: transparent;

      border: none;

      &::placeholder {
        color: color(marriott-light-grey);
        font-size: 12px;
      }
    }

    .clear-input-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 100%;

      color: color(marriott-light-grey);
      font-size: 22px;
    }
  }
}
