@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.sidebar {
  //

  .sticky {
    z-index: 6;
  }
}
