@import "@STYLES/colors.scss";

.vendor-form {
  //

  .visibility-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .checkbox-title {
      margin-right: 15px;

      color: color(marriott-light-grey);
      font-weight: 700;
      font-size: 14px;
    }
  }

  .input-wrapper {
    margin-bottom: 10px;
  }
}
