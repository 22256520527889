@import "@STYLES/colors.scss";

.variant-tags-controls {
  display: flex;
  align-items: center;

  .select-wrapper {
    width: 260px;
  }

  .buttons-wrapper {
    display: flex;
    align-self: flex-end;

    .button {
      padding-left: 10px;

      &:not(:last-child) {
        margin-right: 10px;
        padding-right: 20px;

        border-right: 1px solid color(marriott-light-grey-2);
      }
    }
  }
}
