@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.section {
  //

  position: relative;

  .anchor {
    $top: $subheader-height + $step-breadcrumbs-height + 78px;

    position: absolute;
    top: -$top;

    visibility: hidden;
  }

  .header {
    //

    .title {
      margin-bottom: 25px;

      color: color(marriott-light-grey);
      font-size: 14px;
      text-align: center;
    }
  }

  .page {
    //

    .body-wrapper {
      position: relative;

      width: fit-content;
      margin: 0 auto;

      .mandatory-label {
        position: absolute;
        bottom: 100%;
        left: 0;

        margin-bottom: 8px;

        color: color(marriott-orange);
        font-weight: 700;
        font-size: 12px;
      }
    }
  }
}
