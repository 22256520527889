@import "@STYLES/colors.scss";

.chapters-sticky-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;

  background-color: color(app-white);

  .title {
    display: flex;
    align-items: center;
    margin-right: 10px;

    color: color(marriott-primary);
    font-weight: 700;
    font-size: 18px;
  }

  .buttons-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 20px;

    border-left: 1px solid color(marriott-light-grey-2);

    .delimiter {
      width: 1px;
      height: 100%;
      margin: 0 20px;

      background-color: color(marriott-light-grey-2);
    }

    .publish-wrapper {
      //
    }

    .order-chapters-button {
      display: flex;
      justify-self: flex-end;
    }
  }
}
