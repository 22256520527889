@import "@STYLES/colors.scss";

.basic-checkbox {
  display: inline-flex;

  label {
    display: flex;
    align-items: center;

    cursor: pointer;

    .icon {
      width: 20px;
      height: 20px;

      background-color: color(app-white);
      border: 1px solid color(marriott-light-grey-2);

      .icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        visibility: hidden;

        svg {
          width: 14px;

          fill: color(marriott-dark-grey);
        }
      }
    }

    .title {
      user-select: none;
      margin-left: 12px;
    }

    input[type="checkbox"]:checked + .icon .icon-wrapper {
      visibility: visible;
    }

    input[type="checkbox"]:disabled {
      & + .icon {
        background-color: hsl(0deg 0% 95%);

        svg {
          fill: color(marriott-light-grey);
        }
      }

      & ~ .title {
        color: color(marriott-light-grey);
      }
    }
  }

  &.disabled {
    pointer-events: none;
  }
}
