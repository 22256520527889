.section {
  //

  .variants-list {
    padding: 50px 0;

    .variant-wrapper {
      &:not(:last-child) {
        margin-bottom: 100px;
      }
    }
  }
}
