.content {
  $gap: 50px;

  .grid {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-right: -$gap;
    margin-bottom: -$gap;

    .grid-item {
      width: calc(calc(100% / 4) - #{$gap});
      margin-right: $gap;
      margin-bottom: $gap;

      @media (max-width: 1600px) {
        width: calc(calc(100% / 3) - #{$gap});
      }

      @media (max-width: 1300px) {
        width: calc(calc(100% / 2) - #{$gap});
      }

      @media (max-width: 800px) {
        width: calc(100% - #{$gap});
        min-width: 300px;
      }

      .add-document-button {
        height: 100%;
      }
    }
  }
}
