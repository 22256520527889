.content-with-sidebar {
  display: flex;

  .layout {
    display: flex;
    width: 100%;
    height: 100%;

    .content-wrapper {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 100%;
      margin-left: 55px;

      @media (max-width: 1200px) {
        margin-left: 30px;
      }
    }
  }
}
