@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";
@import "@STYLES/mixins.scss";
@import "@STYLES/pdf.scss";

.comparison-pair {
  transform: translateY(-1px);

  .pair-header {
    display: flex;
    justify-content: center;
    height: 34px;

    background-color: color(app-white);

    .date,
    .author {
      display: flex;
      align-items: center;
      height: 100%;

      font-weight: 700;
      font-size: 12px;
    }

    $indent: 15px;

    .left {
      width: 50%;
      margin-right: -1px;
      padding-right: $indent;

      border-right: 1px solid color(marriott-light-grey-2);

      .date {
        display: flex;
        justify-content: flex-end;

        color: color(marriott-primary);
      }
    }

    .right {
      display: flex;
      align-items: center;
      width: 50%;
      padding-left: $indent;

      .author {
        color: color(marriott-light-grey);
      }

      .version-control {
        margin-left: 15px;
        padding-left: 15px;

        color: color(app-green);
        font-weight: bold;
        font-size: 12px;

        border-left: 1px solid color(marriott-light-grey-2);

        &.not-in-control {
          color: color(marriott-orange);
        }
      }
    }
  }

  .body-wrapper {
    width: 100%;
    margin-bottom: -1px;
    overflow-x: auto;
    overflow-y: hidden;

    border-bottom: 1px solid color(marriott-light-grey-2);

    @include custom-scrollbar($thumb-color: lightgrey, $track-color: rgba(249, 249, 249, 1));

    .body {
      $padding: 40px;

      display: flex;

      .old {
        padding-right: $padding / 2;
        padding-left: $padding;
      }

      .new {
        padding-right: $padding;
        padding-left: $padding / 2;
      }

      .old,
      .new {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;

        padding: 50px 0 90px;

        .pair-body-header {
          display: flex;
          justify-content: center;
          width: 100%;
          margin-bottom: 20px;

          color: color(marriott-light-grey);
          font-weight: 700;
          font-size: 14px;
        }

        .page {
          width: 100%;
          min-width: $pdf-page-width;
          height: 100%;

          .page-placeholder {

            width: $pdf-page-width;
            height: 100%;

            border: 1px dashed color(marriott-light-grey);

            &.is-landscape {
              width: $pdf-page-height;
            }
          }
        }
      }

      .arrow-wrapper {
        position: relative;

        display: flex;
        align-items: center;

        &::before {
          position: absolute;
          left: 50%;

          width: 1px;
          height: 100%;

          background-color: color(marriott-light-grey-2);

          content: "";
        }

        .arrow {
          position: relative;
          z-index: 2;

          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;

          background-color: color(app-white);
          border: 1px solid color(marriott-light-grey-2);
          border-radius: 50%;

          svg {
            transform: translateX(1px);

            fill: color(marriott-light-grey);
          }
        }
      }
    }
  }

  &.is-last {
    .body-wrapper .body {
      .old,
      .new {
        padding-bottom: 140px;
      }
    }
  }
}
