@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";
@import "@STYLES/mixins.scss";

.order-chapters-drawer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 400px;
  min-height: 100%;

  .header {
    position: sticky;
    top: 0;
    z-index: 12;

    display: flex;
    align-items: center;
    height: $main-header-height;
    margin-bottom: 30px;
    padding: 0 30px;

    background-color: color(app-white);
    box-shadow: $main-box-shadow;

    .title {
      color: color(marriott-primary);
      font-weight: 700;
      font-size: 14px;
    }
  }

  .chapters-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 30px;

    .chapter {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 12px 20px;

      background-color: color(marriott-primary);
      border-radius: $main-border-radius;

      transition: background-color 0.1s ease-in-out;

      @include hover-highlight(0.1);

      &.dragged {
        background-color: color(app-green);
      }

      .item-number {
        margin-right: 15px;
        padding-right: 15px;

        color: color(app-white);
        font-weight: 700;
        font-size: 14px;

        border-right: 1px solid color(app-white);
      }

      .item-name {
        margin-top: 2px;

        color: color(app-white);
        font-size: 12px;
        line-height: 150%;
      }
    }
  }

  .footer {
    position: sticky;
    bottom: 0;

    width: 100%;
    margin-top: 15px;
    padding: 15px 30px;

    background-color: color(app-white);
    box-shadow: $main-box-shadow;
  }
}
