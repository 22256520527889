@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";
@import "@STYLES/mixins.scss";

.preview-section-base-text-modal {
  $modal-header-height: 60px;
  height: 100%;

  overflow: hidden;

  border-radius: $main-border-radius;

  .header {
    position: sticky;
    top: 0;
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $modal-header-height;
    padding: 0 20px;

    background-color: color(app-white);
    border-bottom: 1px solid color(marriott-light-grey-2);
    box-shadow: $main-box-shadow;

    .title-wrapper {
      display: flex;
      align-items: center;

      .title {
        font-weight: bold;
        font-size: 18px;
      }
    }

    .pdf-button-wrapper {
      margin-right: 30px;
      margin-left: auto;
    }

    .close-button-wrapper {
      display: flex;
      align-items: center;
      height: 100%;

      .close-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;

        font-size: 30px;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - #{$modal-header-height});
    overflow-x: hidden;
    overflow-y: auto;

    background-color: color(app-bg);

    @include custom-scrollbar($thumb-color: transparent, $track-color: rgba(249, 249, 249, 1));

    &:hover {
      @include custom-scrollbar($thumb-color: lightgrey, $track-color: rgba(249, 249, 249, 1));
    }

    .page-wrapper {
      width: 100%;
      padding: 30px 60px;
    }
  }
}
