@import "@STYLES/colors.scss";

.version-info {
  //

  .latest-updates-title {
    margin-bottom: 30px;
    padding-top: 5px;

    color: color(marriott-primary);
    font-weight: 700;
    font-size: 12px;
  }

  .included-to-version-control {
    margin-bottom: 30px;

    color: color(app-green);
    font-weight: bold;
    font-size: 12px;

    &.not-in-control {
      color: color(marriott-orange);
    }
  }

  .version-info-title {
    margin-bottom: 10px;

    font-weight: 700;
    font-size: 12px;
  }

  .version-info-data {
    color: color(marriott-light-grey);
    font-size: 12px;
    line-height: 150%;
  }

  .version-info-item {
    //

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
