@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.publish-chapter-modal {
  $modal-header-height: 60px;
  height: 100%;

  overflow: hidden;

  border-radius: $main-border-radius;

  .header-wrapper {
    height: $modal-header-height;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - #{$modal-header-height});

    background-color: color(app-bg);
  }
}
