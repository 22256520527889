@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.content {
  $pagination-height: 60px;

  .table-wrapper {
    padding-bottom: $pagination-height - 1px;
  }

  .pagination-wrapper {
    position: fixed;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: calc(100vw - 350px);
    height: $pagination-height;

    background-color: color(app-bg);

    border-top: 1px solid color(marriott-light-grey-2);

    @media (max-width: 1200px) {
      left: 300px;

      width: calc(100vw - 300px);
    }
  }
}
