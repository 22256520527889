@import "@STYLES/colors.scss";

.variant-info {
  position: relative;

  height: 100%;

  .info {
    position: sticky;
    top: 0;

    padding: 30px 0;

    .warning-wrapper {
      margin-bottom: 20px;
    }

    .delimiter {
      width: 50%;
      height: 1px;
      margin: 30px 0;

      background-color: color(marriott-light-grey-2);
    }

    .edit-button-wrapper {
      margin-bottom: 20px;

      .edit-button {
        display: inline-flex;
        align-items: center;
        width: auto;

        svg {
          fill: color(marriott-primary);
        }

        .edit-button-title {
          margin-left: 6px;
          padding-top: 4px;

          color: color(marriott-primary);
          font-weight: 700;
          font-size: 12px;
        }

        &:hover {
          .edit-button-title {
            text-decoration: underline;
          }
        }
      }
    }

    .variant-item-properties {
      //

      .property {
        display: flex;
        justify-content: space-between;

        font-size: 12px;

        &:not(:last-child) {
          margin-bottom: 15px;
        }

        .property-title {
          margin-right: 10px;

          color: color(marriott-light-grey);
          font-weight: 700;
        }

        .property-value {
          //
        }
      }
    }

    .default-variant {
      color: color(marriott-light-grey);
      font-weight: 700;
      font-size: 14px;
    }
  }
}
