@import "@STYLES/colors.scss";

.base-text-info {
  position: relative;

  height: 100%;

  .info {
    position: sticky;

    .warning-wrapper {
      margin-bottom: 30px;
    }

    .delimiter {
      width: 50%;
      height: 1px;
      margin: 30px 0;

      background-color: color(marriott-light-grey-2);
    }

    .title {
      margin-bottom: 30px;

      color: color(marriott-light-grey);
      font-weight: 700;
      font-size: 18px;
    }

    .edit-button-wrapper {
      margin-bottom: 20px;

      .edit-button {
        display: inline-flex;
        align-items: center;
        width: auto;

        svg {
          fill: color(marriott-primary);
        }

        .edit-button-title {
          margin-left: 6px;
          padding-top: 4px;

          color: color(marriott-primary);
          font-weight: 700;
          font-size: 12px;
        }

        &:hover {
          .edit-button-title {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
