@import "@STYLES/colors.scss";

.history-pagination {
  position: sticky;
  top: 0;
  z-index: 1;

  display: flex;
  align-items: center;

  width: fit-content;
  height: 34px;
  margin-bottom: -34px;

  .prev-next {
    display: flex;
    align-items: center;
    height: 100%;

    .pages {
      display: flex;
      margin: 0 8px;

      .current-page,
      .separator,
      .all-pages {
        height: 100%;
        padding-top: 2px;

        color: color(marriott-light-grey);
        font-size: 12px;
      }

      .separator {
        margin: 0 6px;
      }
    }

    .prev-button {
      .icon {
        transform: rotate(-180deg);
      }
    }

    .prev-button,
    .next-button {
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        display: flex;
        align-items: center;

        svg {
          fill: color(marriott-dark-grey);
        }
      }

      &:disabled {
        pointer-events: none;

        .icon svg {
          fill: color(marriott-light-grey);
        }
      }

      &:hover {
        .icon svg {
          fill: color(marriott-primary);
        }
      }
    }
  }
}
