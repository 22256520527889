@import "@STYLES/colors.scss";

.properties {
  //

  .properties-title {
    margin-bottom: 30px;

    color: color(marriott-primary);
    font-weight: 700;
    font-size: 14px;
  }

  .active-user-checkbox {
    margin-bottom: 20px;
  }

  .expiration-date {
    display: flex;
    align-items: center;

    .title {
      margin-right: 15px;

      font-size: 12px;

      &.disabled {
        color: color(marriott-light-grey);
      }
    }

    .date-picker-wrapper {
      //
    }
  }
}


.required {
  font-size: 12px;
  color: color(marriott-orange);
}

.label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(53, 57, 68);
  padding-bottom: 16px;
}

.roles-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .role {
    width: 100%;
    max-width: 50%;
    margin-bottom: 20px;
  }
}
