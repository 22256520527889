@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.page {
  //

  h1 {
    margin: 50px 0 30px;
  }

  .document {
    margin-bottom: 40px;

    .document-title {
      margin-bottom: 20px;

      font-weight: 700;
      font-size: 22px;
    }

    .document-info {
      display: flex;
      align-items: baseline;

      .document-name,
      .document-extraction {
        font-weight: 700;
      }

      .document-name {
        margin-right: 20px;

        color: color(marriott-primary);
        font-size: 22px;
      }

      .document-extraction {
        color: color(marriott-light-grey);
        font-size: 16px;
      }
    }
  }
}
