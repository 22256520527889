@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.page {
  display: flex;
  align-items: center;
  justify-content: center;

  .content-wrapper {
    width: 425px;
    margin-top: 70px;
    margin-right: auto;
    margin-left: auto;

    .content {
      margin-bottom: 15px;
      padding: 20px 30px 30px;

      background-color: color(app-white);
      border: 1px solid color(marriott-light-grey-2);
      border-radius: $main-border-radius;
      box-shadow: $main-box-shadow;

      .title {
        margin-bottom: 20px;

        font-weight: 700;
        font-size: 18px;
      }

      .select-wrapper {
        margin-bottom: 20px;
      }

      .watermark-input {
        padding-bottom: 40px;
      }

      .link-wrapper {
        padding-bottom: 16px;
        //
        .link-title {
          padding-bottom: 6px;

          font-weight: 700;
          font-size: 12px;
        }

        textarea {
          width: 100%;
          padding: 20px 10px;

          border: 1px solid color(marriott-light-grey);
          border-radius: $main-border-radius;

          resize: none;
        }
      }
    }
  }
}
