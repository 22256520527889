@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";
@import "@STYLES/mixins.scss";

.bg {
  position: fixed;
  top: 0;
  left: 0;

  z-index: 10;

  width: 100vw;
  height: 100vh;

  background-color: rgb(0 0 0 / 60%);
}

.result-set-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;

  width: 100%;
}

.result-set-wrapper {
  $indent: 20px;
  position: absolute;
  top: calc(100% + 15px);
  left: 50px;
  z-index: 11;

  display: flex;

  width: calc(100vw - 100px);

  max-height: calc(100vh - #{$main-header-height + $subheader-height + $admin-breadcrumbs-height + ($indent + 15px)});
  overflow: hidden;

  background-color: color(app-bg);
  border-radius: $main-border-radius;

  @media (max-width: 1200px) {
    left: 20px;

    width: calc(100vw - 40px);
  }

  .body {
    width: 100%;
    max-height: 100%;

    overflow-x: hidden;
    overflow-y: auto;

    @include custom-scrollbar($thumb-color: transparent, $track-color: rgba(249, 249, 249, 1));

    &:hover {
      @include custom-scrollbar($thumb-color: lightgrey, $track-color: rgba(249, 249, 249, 1));
    }
  }
}
