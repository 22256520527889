@import "@STYLES/colors.scss";

.change-order-button {
  display: flex;
  align-items: center;

  .order-icon {
    display: flex;
    align-items: center;
    margin-right: 5px;

    svg {
      fill: color(marriott-light-grey);
    }
  }

  .button-title {
    color: color(marriott-light-grey);
    font-weight: 700;
    font-size: 14px;
  }

  &:hover {
    .order-icon {
      svg {
        fill: color(marriott-primary);
      }
    }

    .button-title {
      color: color(marriott-primary);
    }
  }
}
