@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.document-tags {
  //

  .tags-wrapper {
    padding: 40px 0;

    .tags-list {
      $gap: 25px;

      display: flex;
      flex-wrap: wrap;
      margin-right: -$gap;
      margin-bottom: -$gap;

      .tag-item-wrapper {
        width: calc(calc(100% / 5) - #{$gap});
        margin-right: $gap;
        margin-bottom: $gap;

        @media (max-width: 1400px) {
          width: calc(calc(100% / 4) - #{$gap});
        }

        @media (max-width: 1200px) {
          width: calc(calc(100% / 3) - #{$gap});
        }

        @media (max-width: 900px) {
          width: calc(calc(100% / 2) - #{$gap});
        }

        @media (max-width: 700px) {
          width: calc(100% - #{$gap});
        }

        .tag-item {
          position: relative;

          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          padding: 20px 15px;

          background-color: color(app-white);
          border: 1px solid color(marriott-light-grey-2);
          border-radius: $main-border-radius;

          .tag-name {
            //

            button {
              display: flex;
              align-items: center;

              .edit-icon-wrapper {
                margin-right: 8px;

                svg {
                  fill: color(marriott-light-grey);
                }
              }

              .name {
                font-size: 12px;
              }

              &:hover {
                .name {
                  text-decoration: underline;
                }
              }
            }
          }

          &.locked {
            background-color: color(app-white);
            border: 1px solid color(marriott-light-grey);

            .tag-name button {
              pointer-events: none;

              .edit-icon-wrapper {
                display: none;
              }

              .name {
                color: color(marriott-light-grey);
              }
            }
          }

          .lock-wrapper {
            position: absolute;
            top: -14px;
            right: -14px;
            z-index: 1;
          }

          .delete-button {
            position: absolute;
            top: 0;
            right: 0;

            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;

            color: color(marriott-orange);
            font-size: 24px;
          }
        }
      }

      .no-tags {
        display: flex;
        align-items: center;
        margin-bottom: $gap;

        color: var(--marriott-light-grey);
        font-size: 14px;
      }
    }

    .button-wrapper {
      width: 455px;
      margin-top: 30px;
    }
  }
}
