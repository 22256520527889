@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.document-image {
  $image-max-width: 500px;

  padding-top: 40px;
  padding-bottom: 40px;

  .image-container {
    position: relative;

    width: 100%;
    max-width: $image-max-width;
    margin-top: 40px;
    padding: 10px;

    background-color: color(app-white);
    border: 1px solid color(marriott-light-grey-2);
    border-radius: $main-border-radius;
    box-shadow: $main-box-shadow;

    .delete-button {
      $size: 30px;
      position: absolute;
      top: -$size / 2;
      right: -$size / 2;

      display: flex;
      align-items: center;
      justify-content: center;
      width: $size;
      height: $size;

      color: color(marriott-orange);
      font-size: 24px;

      background-color: color(app-white);
      border: 1px solid color(marriott-light-grey-2);
      border-radius: 50%;
      box-shadow: $main-box-shadow;
    }

    .image-wrapper {
      display: flex;
      width: 100%;
      height: 100%;
      overflow: hidden;

      border-radius: $main-border-radius - 1px;

      img {
        width: 100%;
        height: 100%;
        margin: 0;
        object-fit: contain;
      }
    }
  }

  .no-image {
    margin: 20px 0;

    color: color(marriott-light-grey);
    font-size: 14px;
  }

  .save-button-wrapper {
    width: 100%;
    max-width: $image-max-width;
    margin-top: 20px;
  }
}
