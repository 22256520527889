@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.date-picker {
  width: 210px;
  height: 36px;

  padding: 0 20px;

  font-size: 12px;
  text-align: center;

  border: 1px solid color(marriott-light-grey);

  border-radius: $main-border-radius;

  cursor: pointer;

  caret-color: transparent;

  &.is-open {
    color: color(marriott-primary);

    border: 1px solid color(marriott-primary);
  }

  & + button[aria-label="Close"] {
    transform: translateX(35px);

    &::after {
      background-color: color(marriott-orange);
    }
  }

  &.disabled {
    color: color(marriott-light-grey);

    border: 1px solid color(marriott-light-grey-2);

    pointer-events: none;

    & + button[aria-label="Close"] {
      visibility: hidden;
    }
  }
}
