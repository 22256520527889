@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.section-not-found {
  padding-top: 30px;

  color: color(marriott-light-grey);
  font-weight: 700;
  font-size: 14px;
  text-align: center;
}

.page {
  //

  .sidebar-header-wrapper {
    position: sticky;
    top: $subheader-height + $admin-breadcrumbs-height;
    z-index: 1;

    width: 349px;
    height: 100%;
  }

  h1 {
    margin: 0 0 30px;
  }

  h2 {
    margin: 0 0 20px;
  }
}
