@import "@STYLES/colors.scss";

.vendors-sticky-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;

  background-color: color(app-white);

  .vendors-title-wrapper {
    display: flex;
    align-items: baseline;
    margin-left: -10px;

    white-space: nowrap;
  }
}
