.grid {
  $gap: 25px;

  .grid-container {
    display: flex;
    flex-wrap: wrap;

    margin-right: -$gap;
    margin-bottom: -$gap;

    .child-wrap {
      margin-right: $gap;
      margin-bottom: $gap;
    }

    .empty {
      background-color: lightgrey;
      visibility: hidden;
    }

    &.default {
      .child-wrap {
        width: calc(calc(100% / 6) - #{$gap});

        @media (max-width: 1700px) {
          width: calc(calc(100% / 5) - #{$gap});
        }

        @media (max-width: 1500px) {
          width: calc(calc(100% / 4) - #{$gap});
        }

        @media (max-width: 1200px) {
          width: calc(calc(100% / 3) - #{$gap});
        }

        @media (max-width: 900px) {
          width: calc(calc(100% / 2) - #{$gap});
        }

        @media (max-width: 500px) {
          width: calc(100% - #{$gap});
        }
      }
    }

    &.has-sidebar {
      .child-wrap {
        width: calc(calc(100% / 5) - #{$gap});

        @media (max-width: 1700px) {
          width: calc(calc(100% / 4) - #{$gap});
        }

        @media (max-width: 1500px) {
          width: calc(calc(100% / 3) - #{$gap});
        }

        @media (max-width: 1200px) {
          width: calc(calc(100% / 2) - #{$gap});
        }

        @media (max-width: 800px) {
          width: calc(100% - #{$gap});
        }
      }
    }
  }
}
