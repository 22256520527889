@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.chapter {
  position: relative;

  .anchor {
    $top: $subheader-height + $step-breadcrumbs-height - 2px;

    position: absolute;
    top: -$top;

    visibility: hidden;
  }

  .chapters-sticky-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;

    background-color: color(app-white);

    .title {
      margin-right: 10px;

      color: color(marriott-primary);
      font-weight: 700;
      font-size: 18px;
    }
  }

  .chapter-body {
    padding: 40px 10px;

    .chapter-introduction-text-wrapper {
      position: relative;

      margin-bottom: 80px;

      .anchor {
        $top: $subheader-height + $step-breadcrumbs-height + 78px;

        position: absolute;
        top: -$top;

        visibility: hidden;
      }
    }

    .sections {
      .section-wrapper {
        &:not(:last-child) {
          margin-bottom: 80px;
        }
      }
    }
  }
}
