.chapters {
  $gap: 35px;

  margin-bottom: 40px;

  .chapters-title {
    margin-bottom: 20px;

    font-weight: 700;
    font-size: 22px;
  }

  .grid {
    display: flex;
    flex-wrap: wrap;

    margin-right: -$gap;
    margin-bottom: -$gap;

    .grid-item {
      position: relative;

      display: flex;
      flex-direction: column;
      width: calc(calc(100% / 6) - #{$gap});
      height: 100%;
      margin-right: $gap;
      margin-bottom: $gap;
      aspect-ratio: 1;

      @media (max-width: 1700px) {
        width: calc(calc(100% / 5) - #{$gap});
      }

      @media (max-width: 1600px) {
        width: calc(calc(100% / 4) - #{$gap});
      }

      @media (max-width: 1400px) {
        width: calc(calc(100% / 3) - #{$gap});
      }

      @media (max-width: 1100px) {
        width: calc(calc(100% / 2) - #{$gap});
      }

      @media (max-width: 800px) {
        width: calc(100% - #{$gap});
      }
    }
  }
}
