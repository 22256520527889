@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.base-text {
  padding-top: 50px;
  padding-bottom: 50px;

  .body {
    display: flex;
    flex: 1;
    overflow: hidden;

    background-color: color(app-white);
    border: 1px solid color(marriott-light-grey-2);
    border-radius: $main-border-radius;

    @media (max-width: 1500px) {
      flex-direction: column;
    }

    .body-wrapper {
      display: flex;
      flex: 1;

      background-color: color(app-white);

      @media (max-width: 1500px) {
        flex-direction: column;
      }

      .editor {
        display: flex;
        flex: 1;

        .editor-wrapper {
          position: relative;

          flex: 1;
          width: 0;
        }
      }

      .body-sidebar-wrapper {
        //
      }
    }
  }
}
