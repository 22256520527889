@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.scroll-to-top-button {
  position: fixed;
  top: 20px;
  z-index: 10;

  display: flex;
  justify-content: flex-end;
  width: 100vw;

  transform: translateY(-30px);

  transition: transform 0.2s ease-in-out;

  pointer-events: none;

  .button {
    $size: 38px;
    display: flex;
    align-items: center;
    justify-content: center;

    width: $size;
    height: $size;
    margin-right: 15px;
    overflow: hidden;

    background-color: white;
    border: 2px solid color(marriott-dark-blue);
    border-radius: 50%;
    transform: rotate(-90deg);
    opacity: 0;

    transition: opacity 0.2s ease-in-out;

    pointer-events: none;

    svg {
      fill: color(marriott-primary);
    }
  }

  &.visible {
    transform: translateY(0);

    .button {
      opacity: 1;

      pointer-events: all;
    }
  }
}
