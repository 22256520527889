// stylelint-disable selector-class-pattern

@import "colors";
@import "design";
@import "pdf";
@import "mixins";

:root {
  @each $key, $value in $colors {
    --#{$key}: #{$value};
  }

  @each $key, $value in $design {
    --#{$key}: #{$value};
  }

  @each $key, $value in $pdf {
    --#{$key}: #{$value};
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  color: color(marriott-dark-grey);
  font-family: Arial, sans-serif;

  outline: none;
}

html,
body {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100%;
}

html {
  margin-right: calc(-1 * (100vw - 100%));
  overflow-x: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: color(app-bg);

  @include custom-scrollbar($thumb-color: color(marriott-light-grey), $track-color: color(marriott-light-grey-2));
}

.container {
  width: 100%;
  height: inherit;
  padding: 0 50px;

  @media (max-width: 1200px) {
    padding: 0 20px;
  }
}

.container-left {
  width: 100%;
  height: inherit;
  padding-left: 50px;

  @media (max-width: 1200px) {
    padding-left: 20px;
  }
}

.container-right {
  width: 100%;
  height: inherit;
  padding-right: 50px;

  @media (max-width: 1200px) {
    padding-right: 20px;
  }
}

.max-constraint {
  // unused
  // max-width: 1920px;
}

.center {
  width: 100%;
  margin: 0 auto;
}

.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;

  white-space: nowrap;

  border: 0;

  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.clear-button {
  width: 100%;
  height: 100%;
  padding: 0;

  background-color: transparent;
  border: none;

  cursor: pointer;
}

.tox-tinymce {
  z-index: 1 !important;

  border: none !important;
  border-radius: 0 !important;
}

.tox-editor-container {
  background-color: color(marriott-light-grey-2) !important;
}

.tox-sidebar-wrap {
  padding: 50px 10px !important;
}

.tox-edit-area__iframe {
  overflow: hidden !important;
}

.tox-edit-area {
  margin: 0 auto !important;

  box-shadow: 0 0 5px rgb(0 0 0 / 15%) !important;
}

body.tox-dialog__disable-scroll {
  overflow: unset !important;
}
