@import "@STYLES/colors.scss";

.sticky-header {
  position: sticky;

  display: flex;
  align-items: center;
  margin-bottom: -2px;

  border-top: 1px solid color(marriott-light-grey-2);
  border-bottom: 1px solid color(marriott-light-grey-2);
}
