@import "@STYLES/colors.scss";

.result-item-title {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 15px;

  font-size: 12px;
  text-align: center;

  .button {
    display: flex;
    align-items: center;
    width: fit-content;

    .title {
      padding-bottom: 2px;

      color: color(marriott-light-grey);
    }

    .edit-icon {
      margin-left: 8px;
    }

    &:hover {
      .title {
        color: color(marriott-primary);
        text-decoration: underline;
      }

      .edit-icon {
        svg {
          fill: color(marriott-primary);
        }
      }
    }
  }
}
