@import "@STYLES/colors.scss";

.publish-button-wrapper {
  margin-left: 60px;
}

.document-not-found {
  padding-top: 30px;

  color: color(marriott-light-grey);
  font-weight: 700;
  font-size: 14px;
  text-align: center;
}

.page {
  //
}
