@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.document-permissions {
  padding-top: 50px;
  padding-bottom: 50px;
  max-width: calc(100vw - 400px);
  width: 100%;

  .permissions-wrapper {
    padding: 25px;
    height: 60vh;
    background-color: color(app-white);
    border: 1px solid color(marriott-light-grey-2);
    border-radius: $main-border-radius;


    .table {
      width: 100%;
      max-width: fit-content;
      height: 100%;
      overflow: scroll;
      border: 1px solid color(marriott-light-grey);
      position: sticky;
    }

    .table table {
      border-spacing: 0;
    }

    .table th {
      border: 1px solid color(marriott-light-grey);
      padding: 5px;
      width: 150px;
      min-width: 150px;
      position: sticky;
      top: 0;
      background: color(app-white);
      font-weight: normal;

    }


    .table td {
      border: 1px solid color(marriott-light-grey);
      padding: 5px;
      width: 150px;
      min-width: 150px;
      height: 44px;
    }

    .table th:nth-child(1),
    .table td:nth-child(1) {
      position: sticky;
      left: 0;
      width: 200px;
      min-width: 200px;
    }


    .table td:nth-child(1) {
      background: color(app-white);
    }

    .table th:nth-child(1) {
      z-index: 2;
    }

    .chapter {
      background-color: color(app-bg);
    }

    .table tr {
      &:hover {
        background-color: color(marriott-light-grey-2) !important;
        .chapter {
          background-color: color(marriott-light-grey-2);
        }
        td:nth-child(1) {
          background: color(marriott-light-grey-2);
        }
      }
    }

    .center {
      text-align: center;
    }

  }
}
