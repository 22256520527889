@import "@STYLES/colors.scss";

.version-info {
  .included-to-version-control {
    margin-bottom: 30px;

    color: color(app-green);
    font-weight: bold;
    font-size: 12px;

    &.not-in-control {
      color: color(marriott-orange);
    }
  }

  .info {
    //

    .info-title {
      margin-bottom: 10px;

      font-weight: 700;
      font-size: 12px;
    }

    .info-data {
      color: color(marriott-light-grey);
      font-size: 12px;
      line-height: 150%;
    }

    .info-item {
      //

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}
