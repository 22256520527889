@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.variant-body {
  display: flex;
  flex-direction: column;
  min-height: 600px;
  overflow: hidden;

  background-color: color(marriott-primary);
  border-radius: $main-border-radius - 1px;

  .subheader-wrapper {
    //
  }

  .body-wrapper {
    display: flex;
    flex: 1;

    background-color: color(app-white);

    @media (max-width: 1500px) {
      flex-direction: column;
    }

    .editor {
      display: flex;
      flex: 1;

      .editor-wrapper {
        position: relative;

        flex: 1;
        width: 0;
      }
    }

    .body-sidebar-wrapper {
      //
    }
  }

  .footer-wrapper {
    //
  }
}
