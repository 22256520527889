@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.step-sidebar {
  $mb: 30px;
  $left: -30px;
  width: 320px;

  padding: 25px;

  background-color: color(app-white);
  border: 1px solid color(marriott-light-grey-2);
  border-radius: $main-border-radius;

  @media (max-width: 1200px) {
    width: 270px;
  }

  .rows {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: -$left;

    font-size: 14px;

    @media (max-width: 1200px) {
      font-size: 12px;
    }

    .row {
      position: relative;

      display: flex;
      justify-content: space-between;

      .dot,
      .line {
        top: 4px;
      }

      .dot {
        position: absolute;
        left: calc(#{$left} - 4px);
        z-index: 1;

        width: 10px;
        height: 10px;

        background-color: color(marriott-light-grey-2);
        border-radius: 50%;

        &.active {
          background-color: color(marriott-primary);
          border: none;
        }

        &.current {
          transform: scale(1.6);
        }
      }

      .line {
        position: absolute;
        left: $left;

        width: 2px;

        background-color: color(marriott-light-grey-2);

        &.active {
          background-color: color(marriott-primary);
        }
      }

      .title {
        width: 100px;

        color: color(marriott-primary);
        font-weight: 700;
      }

      .values {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        text-align: right;

        .value {
          //

          &.property-name {
            display: flex;
            margin-top: 10px;

            font-size: 12px;

            .property-name-title {
              margin-right: 10px;

              font-weight: 700;
            }

            .property-name-value {
              //
            }
          }
        }
      }

      &:not(:last-child) {
        margin-bottom: $mb;
      }

      &:not(:last-child) .line {
        height: calc(100% + #{$mb});
      }

      &.document .values {
        color: color(marriott-orange);
        font-weight: 700;
      }
    }
  }
}
