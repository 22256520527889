@import "@STYLES/colors.scss";

.introduction-text-sticky-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 34px;

  background-color: color(app-white);

  .introduction-text-title {
    margin-right: 10px;

    color: color(marriott-primary);
    font-weight: 700;
    font-size: 14px;
  }

  .chapter-prefix {
    margin-right: 15px;

    color: color(marriott-light-grey);
    font-weight: 500;
    font-size: 14px;
  }

  .chapter-name {
    color: color(marriott-light-grey);
    font-weight: 700;
    font-size: 14px;
  }

  .delimiter {
    width: 1px;
    height: 100%;

    background-color: color(marriott-light-grey-2);
  }

  .left,
  .right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    height: 100%;
  }

  .left {
    padding-left: 30px;
  }

  .right {
    padding-left: 15px;
  }
}
