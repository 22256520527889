@import "@STYLES/colors.scss";

.delete-item-modal {
  width: 400px;
  padding: 20px;

  .title {
    margin-bottom: 20px;

    font-weight: bold;
    font-size: 18px;
  }

  .question {
    margin-bottom: 20px;

    font-size: 14px;
    line-height: 150%;

    .item-name {
      font-weight: 700;
    }

    .delete-highlight {
      color: color(marriott-orange);
      font-weight: 700;
    }
  }

  .description {
    margin-bottom: 20px;

    color: color(marriott-light-grey);
    font-size: 14px;
  }

  .answers {
    $mr: 15px;
    display: flex;

    margin-right: -$mr;

    .button-wrapper {
      width: calc(50% - #{$mr});
      margin-right: $mr;
    }
  }
}
