@import "@STYLES/colors.scss";

.publish-all-button {
  position: relative;

  display: flex;
  align-items: center;

  .icon,
  .text {
    height: 100%;

    color: color(app-yellow);
  }

  .lock-wrapper {
    position: absolute;
    left: -7px;
  }

  .icon-wrapper {
    position: absolute;
    left: 2px;

    .icon {
      font-size: 28px;

      transform: rotate(-90deg) translateY(-2px);
      transform-origin: center center;
    }
  }

  .text {
    display: flex;
    align-items: center;
    margin-left: 30px;

    font-weight: 700;
    font-size: 14px;
  }

  &:hover {
    .icon,
    .text {
      filter: brightness(1.1);
    }
  }

  &.locked {
    pointer-events: none;

    .text {
      color: color(marriott-light-grey);
    }

    &:disabled {
      .lock-wrapper {
        opacity: 0.4;
      }
    }
  }

  &:disabled {
    pointer-events: none;

    .icon,
    .text {
      color: color(marriott-light-grey-2);
    }
  }
}
