@import "@STYLES/colors.scss";

.document-permissions-controls {
  display: flex;
  align-items: center;

  width: 100%;

  .select-wrapper {
    position: relative;

    width: 260px;
    margin-right: 10px;
  }

  .buttons-wrapper {
    display: flex;
    flex: 1;
    align-self: flex-end;
    justify-content: space-between;
    width: 100%;

    .button {
      padding-left: 10px;
    }
  }
}
