@import "@STYLES/colors.scss";

.chapters-filters {
  padding-right: 25px;
  padding-bottom: 50px;

  .title {
    margin-top: 35px;
    margin-bottom: 35px;

    color: color(marriott-primary);
    font-weight: 700;
    font-size: 16px;
  }

  .checkboxes {
    //

    .checkbox-wrapper {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
}
