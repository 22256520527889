@import "@STYLES/colors.scss";

.variants-sticky-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 34px;
  padding: 0 30px;

  background-color: color(app-white);

  .variants-title-wrapper {
    display: flex;
    align-items: baseline;

    white-space: nowrap;

    .title {
      margin-right: 10px;

      color: color(marriott-primary);
      font-weight: 700;
      font-size: 14px;
    }
  }
}
