@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.tooltip {
  $main-color: color(marriott-dark-blue);

  z-index: 1;

  padding: 12px 18px;

  color: color(app-white);
  font-size: 13px;

  background-color: $main-color;
  border-radius: $main-border-radius;

  .arrow {
    background-color: $main-color;
  }
}
