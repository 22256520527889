@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.content {
  padding-bottom: $wide-button-height;

  .chapters {
    margin-top: 60px;

    .chapter-wrapper {
      &:not(:last-child) {
        margin-bottom: 60px;
      }
    }
  }
}
