@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.variant-header {
  width: 100%;
  padding: 20px 0;

  background-color: color(app-white);
  border-bottom: 1px solid color(marriott-light-grey-2);
  box-shadow: $main-box-shadow;

  .variant-properties {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .property {
      display: flex;
      flex-direction: column;
      width: 20%;
      padding: 0 20px;

      font-size: 12px;

      &:not(:last-child) {
        border-right: 1px solid color(marriott-light-grey-2);
      }

      .property-title {
        margin-bottom: 20px;

        color: color(marriott-light-grey);
        font-weight: 700;
        text-align: center;
      }

      .property-value {
        line-height: 150%;
        text-align: center;
      }
    }
  }

  .default-variant {
    padding-left: 20px;

    color: color(marriott-light-grey);
    font-weight: 700;
    font-size: 14px;
  }
}
