@import "@STYLES/colors.scss";

.section-vendors {
  padding-top: 50px;
  padding-bottom: 50px;

  background-color: color(app-white);

  .vendors-list {
    $gap: 25px;

    display: flex;
    flex-wrap: wrap;
    margin-right: -$gap;
    margin-bottom: 15px;

    .vendor-item-wrapper {
      width: calc(calc(100% / 3) - #{$gap});
      margin-right: $gap;
      margin-bottom: $gap;

      @media (max-width: 1600px) {
        width: calc(calc(100% / 2) - #{$gap});
      }

      @media (max-width: 1200px) {
        width: calc(100% - #{$gap});
      }
    }
  }

  .no-vendors {
    margin-bottom: 40px;

    color: color(marriott-light-grey);
    font-size: 14px;
  }

  .new-vendor-wrapper {
    //
  }
}
