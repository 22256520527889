@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.edit-section-sidebar {
  position: relative;

  width: 100%;

  &.active {
    z-index: 5;
  }

  .wrapper {
    //

    .sections-title {
      position: relative;
      z-index: 2;

      padding-top: 25px;
      padding-right: 25px;
      padding-bottom: 35px;

      color: color(marriott-primary);
      font-weight: 700;
      font-size: 16px;

      background-color: color(app-white);
    }

    .sections-list {
      //
      .section-list-item {
        $p: 30px;

        position: relative;

        padding-bottom: $p;

        background-color: color(app-white);

        &:not(:first-child) {
          padding-top: $p;
        }

        &:not(:last-child) {
          border-bottom: 1px solid color(marriott-light-grey-2);
        }

        .accent {
          position: absolute;
          top: -1px;
          left: 0;

          display: none;
          width: 8px;
          height: calc(100% + 2px);

          background-color: color(marriott-primary);
        }

        &.is-active-section {
          .accent {
            display: block;
          }
        }

        .variants {
          margin-top: 10px;
          padding-right: 25px;
          padding-left: 30px;

          .variants-list-title {
            margin-bottom: 15px;

            font-weight: 700;
            font-size: 12px;
          }

          .variants-list {
            //

            .variant {
              color: color(marriott-light-grey);
              font-size: 12px;
              line-height: 150%;
              text-align: left;

              &:hover {
                text-decoration: underline;
              }

              &:not(:last-child) {
                margin-bottom: 15px;
              }
            }
          }
        }
      }
    }
  }
}
