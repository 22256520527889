@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.login-form {
  position: absolute;
  top: 25%;
  left: 50%;

  width: 400px;
  height: auto;
  padding: 25px;

  background-color: color(app-white);
  border: 1px solid color(marriott-light-grey-2);
  border-radius: $main-border-radius;
  box-shadow: $main-box-shadow;
  transform: translate(-50%, -25%);

  .input-wrapper {
    margin-bottom: 10px;
  }
}
