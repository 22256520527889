@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.tab-radio {
  display: flex;
  flex-direction: column;

  .title {
    padding-bottom: 6px;

    font-weight: 700;
    font-size: 12px;
  }

  .radios {
    display: flex;
    overflow: hidden;

    border-radius: $main-border-radius;

    label {
      width: 100%;

      cursor: pointer;

      .content {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;

        color: color(marriott-light-grey);
        font-weight: bold;
        font-size: 12px;

        background-color: color(marriott-light-grey-2);

        user-select: none;
      }

      input[type="radio"]:checked + .content {
        color: color(app-white);

        background-color: color(marriott-primary);
      }
    }
  }
}
