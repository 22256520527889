.publish-chapter-introduction-text-modal {
  width: 360px;
  padding: 20px;

  .title {
    margin-bottom: 20px;

    font-weight: 700;
    font-size: 18px;
  }

  .footer {
    //
  }
}
