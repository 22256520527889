@import "@STYLES/colors.scss";

.base-modal-sidebar {
  width: 100%;
  padding: 25px;

  .form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .form-wrapper {
      //
      .title {
        margin-bottom: 20px;

        color: color(marriott-light-grey);
        font-weight: 700;
        font-size: 14px;
      }
    }

    .publish-button-wrapper {
      //
    }
  }
}
