.new-vendor-modal {
  width: 440px;
  padding: 25px 35px 35px;

  .title {
    margin-bottom: 25px;

    font-weight: bold;
    font-size: 18px;
  }
}
