@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.content {
  padding-bottom: 60px;

  .mandatory-subheader,
  .section-tags-subheader,
  .section-vendors-subheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;

    background-color: color(app-white);

    .title {
      color: color(marriott-primary);
      font-weight: 700;
      font-size: 18px;
    }
  }

  .base-text-wrapper {
    //
  }

  .variants-list {
    $gap: 120px;
    $gap-less: 20px;

    margin-top: 50px;

    .add-variant-button {
      width: 100%;
      height: 60px;
      margin: 60px 0;
    }

    .variant-item-wrapper {
      position: relative;

      .delimiter {
        position: absolute;
        top: (-$gap + 2px) / 2;
        right: calc(100% + 50px);
        z-index: 1;

        width: 350px;
        height: 1px;

        background-color: color(marriott-light-grey-2);

        @media (max-width: 1200px) {
          right: calc(100% + 20px);

          width: 330px;
        }
      }

      &:not(:last-child) {
        margin-bottom: $gap;
      }

      &.is-close {
        .delimiter {
          top: (-$gap-less + 2px) / 2;

          visibility: hidden;
        }

        &:not(:last-child) {
          margin-bottom: $gap-less;
        }
      }
    }
  }
}
