@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.content {
  padding-bottom: $wide-button-height;

  .chapters-not-found {
    color: color(marriott-light-grey);
    font-size: 16px;
  }
}
