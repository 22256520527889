@import "@STYLES/colors.scss";

.save-and-publish-pair-buttons {
  $gap: 18px;

  display: flex;

  margin-right: -$gap;

  background-color: color(app-white);

  .button-wrapper {
    margin-right: $gap;

    &.save {
      width: calc(40% - #{$gap});
    }

    &.save-and-publish {
      width: calc(60% - #{$gap});
    }
  }
}
