@import "@STYLES/colors.scss";

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 60px;

  .chapter-tags-subheader,
  .chapter-image-subheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;

    background-color: color(app-white);
  }

  .title {
    color: color(marriott-primary);
    font-weight: 700;
    font-size: 18px;
  }

  .sections-wrapper {
    position: relative;

    .add-section-button {
      width: 100%;
      height: 60px;
      margin: 50px 0;
    }

    .sections {
      display: flex;
      flex-direction: column;
      margin-top: 40px;

      .section-wrapper {
        &:not(:last-child) {
          margin-bottom: 50px;
        }

        &.collapsed:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }

    .sections-not-found {
      margin: 20px 0;

      color: color(marriott-light-grey);
    }
  }
}
