@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.section-body {
  $gap: 30px;
  $padding: 2px;
  padding: 0 $padding $padding $padding;

  background-color: color(marriott-primary);
  border-bottom-right-radius: $main-border-radius;
  border-bottom-left-radius: $main-border-radius;

  .base-text-warning-wrapper {
    padding: 25px 25px 0;

    background-color: color(app-white);
  }

  .section-tags {
    padding: $gap;

    background-color: color(app-white);

    $tag-gap: 15px;

    .section-tags-title {
      margin-bottom: $gap;

      font-weight: 700;
      font-size: 16px;
    }

    .section-tags-list {
      display: flex;
      flex-wrap: wrap;
      margin-right: -$tag-gap;
      margin-bottom: -$tag-gap;

      .section-tag-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(20% - #{$tag-gap});
        margin-right: $tag-gap;
        margin-bottom: $tag-gap;
        padding: 10px;

        font-size: 12px;
        line-height: 150%;
        text-align: center;

        background-color: color(app-bg);
        border: 1px solid color(marriott-light-grey-2);
        border-radius: $main-border-radius;
      }
    }
  }

  .variants {
    padding: $gap;

    background-color: color(app-white);
    border-bottom-right-radius: $main-border-radius - 1px;
    border-bottom-left-radius: $main-border-radius - 1px;

    .variants-title {
      margin-bottom: $gap;

      font-weight: 700;
    }

    .variants-list {
      display: flex;
      flex-wrap: wrap;

      margin-right: -$gap;
      margin-bottom: -$gap;

      .variant-item {
        position: relative;

        width: calc(33.33% - #{$gap});
        margin-right: $gap;
        margin-bottom: $gap;
        padding: 20px;
        overflow: hidden;

        background-color: color(app-white);
        border: 1px solid color(marriott-light-grey);
        border-radius: $main-border-radius;
        cursor: pointer;

        &:hover {
          $hover-color: color(app-green);

          border-color: $hover-color;
          box-shadow: 0 0 0 1px $hover-color;
        }

        @media (max-width: 1700px) {
          width: calc(50% - #{$gap});
        }

        @media (max-width: 1400px) {
          width: calc(100% - #{$gap});
        }

        .variant-warning-wrapper {
          margin-bottom: 20px;
        }
      }
    }

    .no-variants {
      color: color(marriott-light-grey);
    }
  }
}
