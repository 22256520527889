@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.table {
  //

  table {
    width: 100%;

    table-layout: auto;

    border-collapse: collapse;

    thead {
      position: sticky;
      top: $subheader-height;

      font-size: 12px;

      background-color: color(app-white);

      th {
        height: 45px;

        .header-cell {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          margin-right: -0.5px;

          border-bottom: 1px solid color(marriott-light-grey-2);
        }

        &:not(:last-child) {
          .header-cell {
            border-right: 1px solid color(marriott-light-grey-2);
          }
        }
      }
    }

    tbody {
      border-bottom: 1px solid color(marriott-light-grey-2);

      tr {
        //

        &:nth-child(even) {
          background-color: color(app-white);
        }

        &:nth-child(odd) {
          background-color: color(app-bg);
        }

        &:not(:last-child) {
          border-bottom: 1px solid color(marriott-light-grey-2);
        }
      }

      td {
        padding: 15px;

        //
        font-size: 12px;
        text-align: center;

        &:not(:last-child) {
          border-right: 1px solid color(marriott-light-grey-2);
        }
      }

      .column-number {
        width: 0;

        color: color(marriott-light-grey);

        white-space: nowrap;
      }

      .user {
        //
      }

      .action {
        //
      }

      .date {
        width: 0;
        padding-right: 23px;

        white-space: nowrap;
      }
    }
  }
}
