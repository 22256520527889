@import "@STYLES/colors.scss";

.circle-checkbox {
  label {
    //

    .icon-wrapper {
      $size: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      width: $size;
      height: $size;

      background-color: color(app-white);
      border: 2px solid color(marriott-light-grey);
      border-radius: 50%;
      cursor: pointer;

      svg {
        visibility: hidden;
      }
    }

    &.disabled {
      pointer-events: none;

      .icon-wrapper {
        background-color: color(app-bg);
        border-color: color(marriott-light-grey-2) !important;
      }
    }

    input[type="checkbox"]:checked + {
      .icon-wrapper {
        border-color: color(marriott-primary);

        svg {
          visibility: visible;
        }
      }
    }
  }
}
